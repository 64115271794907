/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/alt-text */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import cookie from 'js-cookie';
import getConfig from 'next/config';
import BoxSearchFlignhts from '@/componentWebs/vietnamTickets/BoxSearchFlignhts';
import { getResponsiveImage } from '@/componentWebs/NbmImageNew';

const { publicRuntimeConfig } = getConfig();

@connect(({ webs: { dataAd } }) => ({
  dataAd,
}))
class Index extends Component {
  state = {};

  render() {
    const { checkLanguage, dataIntroduse } = this.state;

    const { data, dataAd } = this.props;
    const newAd =
      (dataAd && dataAd.list && dataAd.list.length > 0 && dataAd.list.filter(x => x.adsPositionsId === '27')) || [];
    const newItem = (newAd[0] && newAd[0].contents) || [];
    const template =
      data.templateLayouts && data.templateLayouts.templates && data.templateLayouts.templates[0];
    const imageResize =
      template && template.templateLayoutTemplates && template.templateLayoutTemplates.imagesResize;
    // const {  }
    // console.log(data, dataIntroduse);
    return (
      // Tin tức trang chủ
      <React.Fragment>
        {/* banner  */}
        <section className="banner">
          <div className="container bodyMobile">
            <div className="banner_wrapper bannerMobile">
              <div className="banner_top">
                <div className="banner_top-title">
                  <h2>{data.name}</h2>
                  <h1>{data.descriptions}</h1>
                </div>
                <div className="banner_top-img hidden-m-t">
                  <img src="/static/vietnamTickets/images/Group 9316.svg" alt="banner-top-img" className='lazyload' width='auto' height="auto" />
                </div>
              </div>
              <BoxSearchFlignhts typeTab="all" />
              <div className="banner-main__img hidden-m-t">
                {newItem &&
                  newItem.length > 0 &&
                  newItem
                    .slice(0, 3)
                    .map((item, index) => (
                      <img key={index} src={`${getResponsiveImage(item.file, imageResize)}`} alt={data.name} className='lazyload' />
                    ))}

                {/* <img
                    src={`${getResponsiveImage(data.image && data.image[1] && data.image[1].file)}`}
                    alt={data.name}
                  />
                  <img
                    src={`${getResponsiveImage(data.image && data.image[2] && data.image[2].file)}`}
                    alt={data.name}
                  /> */}
                {/* <img src="/static/vietnamTickets/images/NoPath - Copy (46).png" alt="" />
                  <img src="/static/vietnamTickets/images/NoPath - Copy (44).png" alt="" /> */}
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default Index;
